<template>
  <v-container class="pt-0" fluid>
    <h1 class="primary--text">פתיחת עוסק מורשה או פטור חינם!</h1>
    <h1 class="primary--text">אונליין ובלי לצאת מהבית</h1>
    <br />
    <h2 class="subtitle-text">
      אנו משתמשים בכלים הדיגיטליים החדשניים ביותר, לניהול העסק מכל מקום ומהנייד,
      ומאפשרים לכם לחסוך זמן יקר וכאב ראש!
    </h2>
    <br />
    <h2>
      תנו לנו לדאוג לניירת ולבירוקרטיה, בזמן שאתם דואגים למה שחשוב:
      <span class="primary--text">לגדול עם העסק להצלחה!</span>
    </h2>

    <v-row justify="center" class="mt-16">
      <v-btn x-large rounded class="primary" @click="mainBtnClick">
        <div class="text-sm-h4">לחץ לפתיחת עסק בחינם עכשיו!</div>
      </v-btn>
    </v-row>

    <v-row
      justify="center"
      class="mt-16"
      @click="gtagEv('campaign_main_phone_btn_click', 'main_phone_btn_click')"
    >
      <v-btn x-large rounded class="primary" :href="contactNumber">
        <div class="text-sm-h4">
          חייגו אלינו! <v-icon large>mdi-phone</v-icon>
        </div>
      </v-btn>
    </v-row>
    <v-row justify="center" class="mt-16">
      <v-btn
        x-large
        rounded
        class="primary"
        @click="
          $vuetify.goTo('#contactUs', 'easeInOutQuad');
          gtagEv('campaign_main_contact_btn_click', 'campaign-click');
        "
      >
        <div class="text-sm-h4">השאירו פרטים ונחזור אליכם</div>
      </v-btn>
    </v-row>

    <v-row justify="center" class="mt-16">
      <h1 class="primary--text">לנהל עסק דיגיטלי במאה ה-21</h1>
    </v-row>

    <v-row justify="space-around" class="mt-7">
      <v-col sm="6" md="4">
        <v-img height="80" contain src="../assets/medal.svg"></v-img>
        <h2 class="primary--text">רואי חשבון מנוסים</h2>
        <p>שמכירים את חוקי המס, ואת הפרקטיקה בשטח כדי לחסוך לכם בתשלומי המס</p>
      </v-col>

      <v-col sm="6" md="4">
        <v-img height="80" contain src="../assets/devices.svg"></v-img>
        <h2 class="primary--text">כל הכלים הדיגיטליים בשירות העסק</h2>
        <p>
          הוצאת חשבוניות, דיווח הוצאות, סליקת אשראי, ועוד דרך אפליקציה חדשנית
          לניהול העסק
        </p>
      </v-col>

      <v-col sm="6" md="4">
        <v-img height="80" contain src="../assets/binder.svg"></v-img>
        <h2 class="primary--text">שירותי הנהלת חשבונות וראיית חשבון</h2>
        <p>כל מה שהעסק שלכם צריך!</p>
      </v-col>
    </v-row>

    <v-row justify="center" class="mt-7">
      <h1 class="subtitle-text">אנחנו נדאג עבורכם להכל!</h1>
    </v-row>

    <br />
    <br />
    <v-row v-for="(item, i) in textList" :key="i" align="center">
      <v-col cols="1">
        <v-img
          max-height="100"
          contain
          src="../assets/certificate.svg"
          class="filter-grey"
        />
      </v-col>
      <v-col>
        <div class="text-list-item">{{ item.text }}</div>
      </v-col>
    </v-row>

    <div class="mt-16">
      <h1 class="primary--text">האפליקציה שלנו</h1>
      <br />
      <h2>
        כך מנהלים עסק במאה ה-21!
      </h2>
      <br />
      <p>
        אנו משתמשים בכלים הדיגיטליים החדשניים ביותר, לניהול העסק מכל מקום
        ומהנייד, ומאפשרים לכם לחסוך זמן יקר וכאב ראש!
      </p>
      <p>
        תנו לנו לדאוג לניירת ולבירוקרטיה, בזמן שאתם דואגים למה שחשוב: לגדול עם
        העסק להצלחה!
      </p>
      <p>
        <strong>
          ניתן גם לסלוק כרטיסי אשראי דרך האפליקציה בעמלה נמוכה במיוחד
        </strong>
      </p>
      <p>
        דרך האפליקציה שלנו תוכלו לשלוח את כל החומר שדרוש להנהלת החשבונות ישירות
        אלינו על ידי צילום פשוט של חשבוניות ההוצאה
      </p>
      <div>ללא צורך לאסוף ולהביא פיזית את המסמכים</div>
      <br />
      <br />

      <v-row justify="space-around">
        <v-col sm="6" md="4">
          <v-img max-height="80" contain src="../assets/report.svg"></v-img>
          <br />
          <h2 class="primary--text">הנפקת חשבוניות ודיווח הוצאות</h2>
          <p>
            האפליקציה מנפיקה עבורכם חשבוניות, קבלות, ומסמכים אחרים, ומעבירה
            אלינו חשבוניות הוצאה לדיווח ובקרה!
          </p>
        </v-col>

        <v-col sm="6" md="4">
          <v-img max-height="80" contain src="../assets/monitor.svg"></v-img>
          <br />
          <h2 class="primary--text">מצב העסק בכל רגע נתון</h2>
          <p>
            בנגיעה קלה במסך הנייד תדעו בדיוק מה מצב העסק, גבייה מלקוחות, הוצאות,
            ותשלומי מס. ככה מנהלים היום עסק!
          </p>
        </v-col>

        <v-col sm="6" md="4">
          <v-img
            max-height="80"
            contain
            src="../assets/credit-cards.svg"
          ></v-img>
          <br />
          <h2 class="primary--text">סליקת אשראי</h2>
          <p>
            באפליקציה ניתן לסלוק כרטיסי אשראי בעמלה נמוכה במיוחד 1% ללא דמי
            שימוש!
          </p>
        </v-col>
      </v-row>

      <!--      <h1>לחצו להורדה</h1>-->

      <!--      <v-row justify="space-around">-->
      <!--        <v-col @click="gtagEv('campaign_app_btn_click', 'campaign-googlePlay')">-->
      <!--          <a-->
      <!--            href="https://play.google.com/store/apps/details?id=finbot.kbnapp&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"-->
      <!--            target="_blank"-->
      <!--          >-->
      <!--            <v-img-->
      <!--              alt="Google Play כעת ב-"-->
      <!--              src="https://play.google.com/intl/en_us/badges/static/images/badges/iw_badge_web_generic.png"-->
      <!--              height="120px"-->
      <!--              width="270"-->
      <!--            />-->
      <!--          </a>-->
      <!--        </v-col>-->

      <!--        <v-col-->
      <!--          align-self="center"-->
      <!--          class="pt-1"-->
      <!--          @click="gtagEv('campaign_app_btn_click', 'campaign-finbot')"-->
      <!--        >-->
      <!--          <v-btn-->
      <!--            x-large-->
      <!--            elevation="7"-->
      <!--            height="80px"-->
      <!--            width="270"-->
      <!--            class="black go-to-site-btn"-->
      <!--            href="https://kbn.finbot.co.il/hs-user"-->
      <!--            target="_blank"-->
      <!--          >-->
      <!--            <v-row class="align-center">-->
      <!--              <v-col>-->
      <!--                <div class="white&#45;&#45;text text-h5 pr-1">כניסה לאתר</div>-->
      <!--              </v-col>-->
      <!--              <v-col cols="4">-->
      <!--                <v-img-->
      <!--                  contain-->
      <!--                  alt="כניסת לקוחות"-->
      <!--                  src="../assets/chrome.svg"-->
      <!--                  width="100"-->
      <!--                  height="60"-->
      <!--                />-->
      <!--              </v-col>-->
      <!--            </v-row>-->
      <!--          </v-btn>-->
      <!--        </v-col>-->

      <!--        <v-col-->
      <!--          align-self="center"-->
      <!--          @click="gtagEv('campaign_app_btn_click', 'campaign-appleStore')"-->
      <!--        >-->
      <!--          <a-->
      <!--            href="https://apps.apple.com/us/app/%D7%97%D7%A9%D7%91%D7%95%D7%A0%D7%99%D7%AA-%D7%9C%D7%A2%D7%A1%D7%A7/id1551563861"-->
      <!--            target="_blank"-->
      <!--          >-->
      <!--            <v-img-->
      <!--              contain-->
      <!--              alt="App Store כעת ב"-->
      <!--              src="../assets/download_on_the_App_Store_Badge_IL_RGB_blk_102517.svg"-->
      <!--              width="270px"-->
      <!--              height="80px"-->
      <!--            />-->
      <!--          </a>-->
      <!--        </v-col>-->
      <!--      </v-row>-->
      <ThankYouPopup :show-thank-you-popup="showThankYouPopup" />
      <FormDetails :show-form="showForm" v-on:close-form="closeForm" />
    </div>
  </v-container>
</template>

<script>
import FormDetails from "@/components/FormDetails";
import ThankYouPopup from "@/components/ThankYouPopup";

export default {
  name: "Campaign",
  components: { ThankYouPopup, FormDetails },
  data: () => ({
    showForm: false,
    showThankYouPopup: false,
    textList: [
      { text: " פתיחת תיק עוסק מורשה, פטור או חברה וייצוג ברשויות המס" },
      { text: " הנהלת חשבונות שוטפת וצמודה" },
      { text: "ליווי צמוד של רואה חשבון מומחה למס" },
      { text: "אפליקצייה להעברת הוצאות באופן דיגיטלי" },
      { text: "אפליקצייה להפקת חשבוניות ללקוחות וסליקת אשראי" },
      { text: 'דוח 2020 ללא עלות ללקוחות חדשים (מתנה בשווי 2000 ש"ח)' }
    ]
  }),
  methods: {
    // eslint-disable-next-line no-unused-vars
    gtagEv(action, label) {
      this.$gtag.event(action, {
        event_label: label,
        event_category: "campaign"
      });
    },
    mainBtnClick() {
      this.openForm();
      this.gtagEv("campaign_main_btn_click", "campaign-click");
    },
    openForm() {
      this.showForm = true;
    },
    closeForm(state) {
      if (state === "done") {
        this.showThankYou();
      }
      this.showForm = false;
    },
    showThankYou() {
      this.showThankYouPopup = true;
      setTimeout(() => {
        this.showThankYouPopup = false;
      }, 3000);
    },
    runGoogleTM(w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != "dataLayer" ? "&l=" + l : "";
      j.async = true;
      j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
      f.parentNode.insertBefore(j, f);
    }
  },
  computed: {
    contactNumber() {
      if (
        this.$route.path === "/campaign" &&
        this.$route.query.campaign === "zap"
      ) {
        return "tel:+972723307127";
      } else {
        return "tel:+972792016611";
      }
    }
  },
  mounted() {
    // this.runGoogleTM(window, document, "script", "dataLayer", "GTM-NNV26H9");
    this.$gtag.pageview({
      page_title: this.$route.name,
      page_path: this.$route.fullPath
    });
  }
};
</script>
<style lang="scss" scoped>
.filter-grey {
  filter: invert(64%) sepia(14%) saturate(312%) hue-rotate(169deg)
    brightness(88%) contrast(85%);
}
.text-list-item {
  font-size: 1.25rem;
}
.subtitle-text {
  color: #bf8e00;
}
.go-to-site-btn {
  border-radius: 14px;
}
</style>
<!--<script>-->
<!--(function(w, d, s, l, i) {-->
<!--  w[l] = w[l] || [];-->
<!--  w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });-->
<!--  var f = d.getElementsByTagName(s)[0],-->
<!--    j = d.createElement(s),-->
<!--    dl = l != "dataLayer" ? "&l=" + l : "";-->
<!--  j.async = true;-->
<!--  j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;-->
<!--  f.parentNode.insertBefore(j, f);-->
<!--})(window, document, "script", "dataLayer", "GTM-NNV26H9");-->
<!--</script>-->
<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NNV26H9"
                  height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
