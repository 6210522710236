<template>
  <v-container class="pt-0" fluid>
    <h1 class="primary--text">פתיחת עוסק מורשה או פטור חינם!</h1>
    <h1 class="primary--text">אונליין ובלי לצאת מהבית</h1>
    <br />
    <h2 class="subtitle-text">
      אנו משתמשים בכלים הדיגיטליים החדשניים ביותר, לניהול העסק מכל מקום ומהנייד,
      ומאפשרים לכם לחסוך זמן יקר וכאב ראש!
    </h2>
    <br />
    <h2>
      תנו לנו לדאוג לניירת ולבירוקרטיה, בזמן שאתם דואגים למה שחשוב:
      <span class="primary--text">לגדול עם העסק להצלחה!</span>
    </h2>

    <v-row justify="center" class="mt-16">
      <v-btn x-large rounded class="primary" @click="openForm">
        <div class="text-sm-h4">לפתיחת עסק בחינם עכשיו!</div>
      </v-btn>
    </v-row>

    <v-row justify="center" class="mt-16">
      <h1 class="primary--text">לנהל עסק דיגיטלי במאה ה-21</h1>
    </v-row>

    <v-row justify="space-around" class="mt-7">
      <v-col sm="6" md="4">
        <v-img height="80" contain src="../assets/medal.svg"></v-img>
        <h2 class="primary--text">רואי חשבון מנוסים</h2>
        <p>שמכירים את חוקי המס, ואת הפרקטיקה בשטח כדי לחסוך לכם בתשלומי המס</p>
      </v-col>

      <v-col sm="6" md="4">
        <v-img height="80" contain src="../assets/devices.svg"></v-img>
        <h2 class="primary--text">כל הכלים הדיגיטליים בשירות העסק</h2>
        <p>
          הוצאת חשבוניות, דיווח הוצאות, סליקת אשראי, ועוד דרך אפליקציה חדשנית
          לניהול העסק
        </p>
      </v-col>

      <v-col sm="6" md="4">
        <v-img height="80" contain src="../assets/binder.svg"></v-img>
        <h2 class="primary--text">שירותי הנהלת חשבונות וראיית חשבון</h2>
        <p>כל מה שהעסק שלכם צריך!</p>
      </v-col>
    </v-row>

    <v-row justify="center" class="mt-7">
      <h1 class="subtitle-text">אנחנו נדאג עבורכם להכל!</h1>
    </v-row>

    <br />
    <br />
    <v-row v-for="(item, i) in textList" :key="i" align="center">
      <v-col cols="1">
        <v-img contain src="../assets/certificate.svg" class="filter-grey" />
      </v-col>
      <v-col>
        <div class="text-list-item">{{ item.text }}</div>
      </v-col>
    </v-row>
    <FormDetails :show-form="showForm" v-on:close-form="closeForm" />
  </v-container>
</template>

<script>
import FormDetails from "@/components/FormDetails";

export default {
  name: "Main",
  components: {
    FormDetails
  },
  data: () => ({
    showForm: false,
    textList: [
      { text: " פתיחת תיק עוסק מורשה, פטור או חברה וייצוג ברשויות המס" },
      { text: " הנהלת חשבונות שוטפת וצמודה" },
      { text: "ליווי צמוד של רואה חשבון מומחה למס" },
      { text: "אפליקצייה להעברת הוצאות באופן דיגיטלי" },
      { text: "אפליקצייה להפקת חשבוניות ללקוחות וסליקת אשראי" },
      { text: 'דוח 2020 ללא עלות ללקוחות חדשים (מתנה בשווי 2000 ש"ח)' }
    ]
  }),
  methods: {
    openForm() {
      this.showForm = true;
    },
    closeForm() {
      this.showForm = false;
    },
    runGoogleTM(w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != "dataLayer" ? "&l=" + l : "";
      j.async = true;
      j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
      f.parentNode.insertBefore(j, f);
    }
  },
  mounted() {
    // this.runGoogleTM(window, document, "script", "dataLayer", "GTM-NNV26H9");
    // console.log("mounted");
    this.$gtag.pageview({
      page_title: this.$route.name,
      page_path: this.$route.fullPath
    });
  }
};
</script>

<!-- Google Tag Manager -->
<!--<script>-->

<!--</script>-->
<!-- End Google Tag Manager -->

<!-- Google Tag Manager (noscript) -->
<!--<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NNV26H9"-->
<!--                  height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>-->
<!-- End Google Tag Manager (noscript) -->

<style lang="scss" scoped>
.filter-grey {
  filter: invert(64%) sepia(14%) saturate(312%) hue-rotate(169deg)
    brightness(88%) contrast(85%);
}
.text-list-item {
  font-size: 1.75rem;
}
.subtitle-text {
  color: #bf8e00;
}
</style>
