import { render, staticRenderFns } from "./Campaign.vue?vue&type=template&id=4ecb28fa&scoped=true&"
import script from "./Campaign.vue?vue&type=script&lang=js&"
export * from "./Campaign.vue?vue&type=script&lang=js&"
import style0 from "./Campaign.vue?vue&type=style&index=0&id=4ecb28fa&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ecb28fa",
  null
  
)

/* custom blocks */
import block0 from "./Campaign.vue?vue&type=custom&index=0&blockType=noscript"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCol,VContainer,VIcon,VImg,VRow})
